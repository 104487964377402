<template>
  <CContainer>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <strong>Editar Usuarios</strong>
          </CCardHeader>
          <CCardBody>
            <CForm validate @submit="actualizarUsuario">
              <CInput
                placeholder="Nombre"
                autocomplete="off"
                minlength="3"
                required
                was-validated
                v-model="form.name"
              >
                <template #prepend-content><CIcon name="cil-user" /></template>
              </CInput>
              <CInput
                placeholder="Email"
                autocomplete="off"
                type="email"
                prepend="@"
                required
                was-validated
                v-model="form.email"
              />
              <CInput
                placeholder="Clave"
                autocomplete="off"
                minlength="6"
                was-validated
                type="password"
                v-model="form.password"
              >
                <template #prepend-content><CIcon name="cil-user" /></template>
              </CInput>
              <CSelect
                placeholder="Pais"
                :options="[{ value: 57, label: 'Colombia (57)' }]"
                required
                was-validated
                :value.sync="form.pais"
              >
                <template #prepend-content
                  ><CIcon name="cil-globe-alt"
                /></template>
              </CSelect>
              <CSelect
                placeholder="Rol"
                :options="roles"
                required
                was-validated
                :value.sync="form.rol"
              >
                <template #prepend-content
                  ><CIcon name="cil-globe-alt"
                /></template>
              </CSelect>
              <CInput
                placeholder="Celular (Sin Indicativo del Pais)"
                autocomplete="off"
                @keypress="isNumber($event)"
                minlength="10"
                maxlength="10"
                required
                was-validated
                v-model="form.celular"
              >
                <template #prepend-content><CIcon name="cil-phone" /></template>
              </CInput>
              <CInput
                placeholder="SenderId"
                autocomplete="off"
                minlength="3"
                was-validated
                v-model="form.senderId"
              >
                <template #prepend-content><CIcon name="cil-user" /></template>
              </CInput>
              <CSelect
                placeholder="Estado"
                :options="status_opcion"
                required
                was-validated
                :value.sync="form.status"
              >
                <template #prepend-content
                  ><CIcon name="cil-globe-alt"
                /></template>
              </CSelect>
              <CInput
                placeholder="Token External MUA"
                autocomplete="off"
                minlength="3"
                v-model="form.token_external_mua"
              >
                <template #prepend-content><CIcon name="cil-user" /></template>
              </CInput>
              <CRow>
                <CCol col="8">
                  <CInput
                    placeholder="Token"
                    autocomplete="off"
                    minlength="6"
                    required
                    was-validated
                    v-model="form.token_integracion"
                    disabled="true"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                </CCol>
                <CCol col="4">
                  <CButton size="sm" color="primary" @click="generarToken()"
                    >Generar Token</CButton
                  >
                </CCol>
              </CRow>
              <hr />
              <CRow>
                <CCol col="6">
                  <router-link to="/usuarios">
                    <CButton color="primary" block>Atras</CButton>
                  </router-link>
                </CCol>
                <CCol col="6">
                  <CButton
                    color="success"
                    type="submit"
                    v-if="$can('usuario.edit')"
                    block
                    >Actualizar</CButton
                  >
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <!--<CCardFooter>
          
        </CCardFooter>-->
        </CCard>
      </CCol>
    </CRow>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            Datos Guardado Correctamente.
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
    </div>
  </CContainer>
</template>

<script>
export default {
  name: "EditarUsuario",
  data() {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      form: {
        name: "",
        email: "",
        pais: "",
        celular: "",
        senderId: "",
        password: "",
        rol: "",
        status: "",
        token_integracion: "",
        token_external_mua: "",
      },
      roles: [],
      status_opcion: [
        { value: 1, label: "Activo" },
        { value: 0, label: "Inactivo" },
      ],
    };
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/roles" });
    },
    obtenerUsuarioPorId() {
      const id = this.$route.params.id;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("/sms/usuarios/" + id)
        .then((response) => {
          let data = response.data;
          this.form.name = data.name;
          this.form.email = data.email;
          this.form.celular = data.celular;
          this.form.senderId = data.SenderId;
          this.form.rol = data.rol_id;
          this.form.pais = data.pais;
          this.form.status = data.status;
          this.form.token_integracion = data.token_integracion;
          this.form.token_external_mua = data.token_external_mua;
        })
        .catch((e) => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("/sms/usuarios/create")
        .then((response) => {
          let data = response.data;
          for (const iterator of data) {
            this.roles.push({ value: iterator.id, label: iterator.name });
          }
        })
        .catch((e) => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    actualizarUsuario(e) {
      e.preventDefault();
      const id = this.$route.params.id;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .put("/sms/usuarios/" + id, {
          email: this.form.email,
          celular: this.form.pais + this.form.celular,
          name: this.form.name,
          password: this.form.password,
          pais: this.form.pais,
          rol_id: this.form.rol,
          status: this.form.status,
          senderId: this.form.senderId,
          token_integracion: this.form.token_integracion,
          token_external_mua: this.form.token_external_mua,
        })
        .then((response) => {
          if (response.data.user) {
            this.mostrarNotificacion++;
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                for (const iterator of element) {
                  mensajeError += `${iterator} \n`;
                }
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
          }
        })
        .catch((error) => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    generarToken() {
      this.form.token_integracion = this.token();
    },
    random() {
      return Math.random().toString(36).substr(2); // Eliminar `0.`
    },
    token() {
      return this.random() + this.random(); // Para hacer el token más largo
    },
  },
  mounted() {
    setTimeout(() => {
      this.obtenerUsuarioPorId();
    }, 100);
  },
};
</script>
